import axios from "axios";
import { ApiDomain } from "../env";


export const POST_API = async(URL,Body)=>{
    let Result = await new Promise((resolve,reject)=>{
        axios({
            // Endpoint to send files
            url: ApiDomain+URL,
            method: "POST",

            headers : { Accept: 'application/json',
            "Content-Type": 'application/json' },
                data: Body,
            })
            
            // Handle the response from backend here
            .then((res) => { 
                return resolve(res)
            })
            // Catch errors if any
            .catch((err) => {
                return reject(err)
            });
        })
    return Result
}

export const PUT_API = async(URL,Body)=>{
    let Result = await new Promise((resolve,reject)=>{
        axios({
            // Endpoint to send files
            url: ApiDomain+URL,
            method: "PUT",

            headers : { Accept: 'application/json',
            "Content-Type":'application/json' },
                data: Body,
            })
            
            // Handle the response from backend here
            .then((res) => { 
                return resolve(res)
            })
            // Catch errors if any
            .catch((err) => {
                return reject(err)
            });
        })
    return Result
}

export const GET_API = async(URL)=>{
    let Result = await new Promise((resolve,reject)=>{
        axios({
            // Endpoint to send files
            url: ApiDomain+URL,
            method: "GET",

            headers : { Accept: 'application/json' },
            })
            
            .then((res) => { 
                return resolve(res)
            })
            // Catch errors if any
            .catch((err) => {
                return reject(err)
            });
        })
    return Result
}
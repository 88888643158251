import React, { useEffect, useState } from 'react'
import './CSS/Main.css'
import SideText from './SideText'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useFetcher, useNavigate } from "react-router-dom"
import { UPLOAD_CLIENT_INFO } from '../common/apiFunction';

const AdFormat = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState([]);
  const [DataDisplay, setDataDisplay] = useState([])

  const queryParams = new URLSearchParams(window.location.search);
  const Inventorytype = (queryParams.get('type'))?.replaceAll('_', ' ');

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (selectedOption.includes(value)) {
      setSelectedOption(selectedOption.filter((item) => item != value)) // Unselect if the same checkbox is clicked again
    } else {
      setSelectedOption(data => [...data, value])
    }
  };


  const HandleSubmit = (() => {
    if (selectedOption.length === 0) {
      message.error(`Select Advertising Platform`);
    }
    else {
      let UserInfo = sessionStorage["ClientEmail"]
      sessionStorage.setItem("Ad_type",Inventorytype)
      let payload = {
        'id': "WHL" + Math.floor(Math.random() * 10000),
        "Email": sessionStorage["ClientEmail"],
        "Ads_Inventory": Inventorytype,
        "Ad_Type": selectedOption
      }
      console.log(payload)
      UPLOAD_CLIENT_INFO(payload).then((data) => {
        if (data?.data?.status === true) {
          navigate('/Upload-Thumbnail')
          sessionStorage["PageNum"] = 4
        }
        else { message.error(data?.data?.message) }
      })
    }
  })

  const FNB_Menu = [
    {
      'key': 1,
      'type': 'Carousel Ad W:720 H:410',
      'thumbnail': 'carousel.png'
    }, {
      'key': 2,
      'type': 'Banner Ad W:720 H:180',
      'thumbnail': 'banner.png'
    }, {
      'key': 3,
      'type': 'Thumbnail',
      'thumbnail': 'thumbnail.png'
    }, {
      'key': 4,
      'type': 'Landing Page',
      'thumbnail': 'carousel.png'
    },
  ]
  //same for Other_section_display_ads, video_Ad , Lead Based Ad
  const Display_Ads = [
    {
      'key': 1,
      'type': 'Carousel Ad W:720 H:410',
      'thumbnail': 'carousel.png'
    }, {
      'key': 2,
      'type': 'Banner Ad W:720 H:180',
      'thumbnail': 'banner.png'
    }, {
      'key': 3,
      'type': 'Pre-roll Ad W:720 H:410',
      'thumbnail': 'mid-roll.png'
    }, {
      'key': 4,
      'type': 'Mid-roll Ad W:720 H:410',
      'thumbnail': 'mid-roll.png'
    }, {
      'key': 5,
      'type': 'Interstitial W:680 H:960',
      'thumbnail': 'interstitial.png'
    }, {
      'key': 6,
      'type': '1 Filled Form W:680 H:960',
      'thumbnail': '1field-form.png'
    }, {
      'key': 7,
      'type': '2 Filled Form W:680 H:960',
      'thumbnail': '2field-form.png'
    }, {
      'key': 8,
      'type': '3 Filled Form W:680 H:960',
      'thumbnail': '3field-form.png'
    }, {
      'key': 9,
      'type': '4 Filled Form W:680 H:960',
      'thumbnail': '4field-form.png'
    },
  ]

  const Home_Page_Ads = [
    {
      'key': 1,
      'type': 'Carousel Ad W:720 H:410',
      'thumbnail': 'carousel.png'
    }, {
      'key': 2,
      'type': 'Banner Ad W:720 H:180',
      'thumbnail': 'banner.png'
    }, {
      'key': 3,
      'type': 'Pre-roll Ad W:720 H:410',
      'thumbnail': 'mid-roll.png'
    }, {
      'key': 4,
      'type': 'Mid-roll Ad W:720 H:410',
      'thumbnail': 'mid-roll.png'
    }, {
      'key': 5,
      'type': 'Interstitial W:680 H:960',
      'thumbnail': 'interstitial.png'
    }, {
      'key': 6,
      'type': '1 Filled Form W:680 H:960',
      'thumbnail': '1field-form.png'
    }, {
      'key': 7,
      'type': '2 Filled Form W:680 H:960',
      'thumbnail': '2field-form.png'
    }, {
      'key': 8,
      'type': '3 Filled Form W:680 H:960',
      'thumbnail': '3field-form.png'
    },
  ]

  useEffect(() => {
    if (Inventorytype === 'FNB Menu') {
      setDataDisplay(FNB_Menu)
    }
    else if (Inventorytype === 'Home Page Ads') {
      setDataDisplay(Home_Page_Ads)
    }
    else {
      setDataDisplay(Display_Ads)
    }
  }, [])



  return (
    <div className='Home-container'>
      <img src={require('../assets/format.png')} className='Home-containerImg' alt='BG img' />
      <SideText pageno={[2,3]}/>
      <div className='Format-Right-container' style={{ top: 60, height: '80%', 'overflowY': 'scroll' }}>
        <h4 className='mb-3'> Ad Format </h4>


        <h6 style={{ 'color': '#fff' }} className='mb-1'>Please select one or multiple ad format</h6>
        {/* <Radio.Group onChange={onChange} value={value} className='d-flex'> */}

        <div className='row d-flex justify-content-around'>
          {
            DataDisplay?.map((item) => {
              return (
                <div key={item.key} className='col-sm-3 mt-4 FormatRadioButton'>
                  <input type='checkbox' id={`id${item.key}`} name='campainType' value={item.type}
                    checked={selectedOption.includes(`${item.type}`)}
                    onChange={handleCheckboxChange} />
                  <label htmlFor={`id${item.key}`}>
                    <img src={require(`../assets/format/${item.thumbnail}`)} alt={item.type} />
                    <p>{item.type}</p></label>
                </div>
              )
            })
          }
        </div>

        <div className='d-flex mt-4 mb-3'>
          <div className='d-flex custom-button-back'
            onClick={() => {
              navigate('/Inventory')
              sessionStorage["PageNum"] = 2
            }}
          >
            <ArrowLeftOutlined className="site-form-item-icon" />
            <input type='button' value='Back' />
          </div>

          <div className='d-flex custom-button'
            onClick={() => {
              HandleSubmit()
              // navigate('/Upload-Thumbnail')
              // sessionStorage["PageNum"] = 4
            }}
          >
            <input type='button' value='Next' />
            <ArrowRightOutlined className="site-form-item-icon" />
          </div>
        </div>



      </div>
    </div>
  )
}

export default AdFormat

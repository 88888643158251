import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inventory from './components/Inventory';
import AdFormat from './components/AdFormat';
import Thumbnail from './components/UploadThumbnail';
import Assets from './components/UploadAssets';

function App() {
  if(sessionStorage["PageNum"] === undefined) sessionStorage.setItem("PageNum",1)
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/Inventory' element={<Inventory/>}></Route>
        <Route path='/Format' element={<AdFormat />}></Route>
        <Route path='/Upload-Thumbnail' element={<Thumbnail />}></Route>
        <Route path='/Upload-Assets' element={<Assets />}></Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;

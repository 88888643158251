import React, { useState } from 'react'
import './CSS/Main.css'
import SideText from './SideText'
import { MailOutlined, UserOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Radio ,message} from 'antd';
import { useNavigate } from "react-router-dom"
import { SAVE_CLIENT_INFO } from '../common/apiFunction';
const { TextArea } = Input;


const Home = () => {
  const [value, setValue] = useState(1);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [Data,setData] = useState([])
 
  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (selectedOption === value) {
      setSelectedOption(null); // Unselect if the same checkbox is clicked again
    } else {
      setSelectedOption(value);
    }
  };

  const handleForm = ((key,e)=>{
    let val = e.target.value;
    setData(value=>({...value,[key]:val}))
  })

  const HandleSubmit = (()=>{
    console.log('Received values of form: ', Data);
    if(Data?.Full_Name === '' || Data?.Full_Name === undefined )
    {message.error(`Enter Full Name`)}
    else if(Data?.Email === '' || Data?.Email === undefined )
    {message.error(`Enter Your Mail`)}
    else if(selectedOption === null)
    {message.error(`Select Advertising Platform`)}
    
    else{
      Data["Platform"] = selectedOption
      console.log(selectedOption)
      sessionStorage.setItem("ClientEmail",Data?.Email)
      SAVE_CLIENT_INFO(Data).then((data)=>{
        console.log(data)
        if(data?.data?.status === true)
        {
          navigate('/Inventory')
          sessionStorage["PageNum"] = 2
        }
        else {message.error(data?.data?.message)}
      })
    }
  })

  const onFinish = () => {
    console.log('Received values of form: ', Data);
    console.log(selectedOption)
  };

  return (
    <div className='Home-container'>
      <img src={require('../assets/home.png')} className='Home-containerImg' alt='BG img' />
      <SideText />
      <div className='Right-container'>
        <h4 className='mb-3'> About You </h4>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            //  style={{'background':'rgba(255, 255, 255, 0.2)','color':'#fff','border':'none'}}
            name="fullname"
            className='Form-Field'
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <div className="custom-input-container">
              <UserOutlined className="site-form-item-icon" />
              <Input
                type="text"
                placeholder="Full Name"
                className="custom-input"
                onChange={(e)=>handleForm('Full_Name',e)}
              />
            </div>

          </Form.Item>
          <Form.Item
            name="email"
            className='Form-Field'
            rules={[{ required: true, message: 'Please input your mail!' }]}
          >
            <div className="custom-input-container">
              <MailOutlined className="site-form-item-icon" />
              <Input
                type="email"
                placeholder="Email Address"
                className="custom-input"
                onChange={(e)=>handleForm('Email',e)}
              />
            </div>
          </Form.Item>

          <h6 style={{ 'color': '#fff' }} className='mb-3'>Select a Platform</h6>
          {/* <Radio.Group onChange={onChange} value={value} className='d-flex'> */}

          <Form.Item>

            <div className='d-flex justify-content-between'>
              <div className='RadioButton'>
                <input
                  type='checkbox'
                  id="rigoscreen"
                  name='campainType'
                  value="rigoscreen"
                  checked={selectedOption === "rigoscreen"}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="rigoscreen">  <img src={require('../assets/platform/rigoscreen.png')} alt="rigoscreen"/> </label>
              </div>

              <div className='RadioButton'>
                <input
                  type='checkbox'
                  id="delhi"
                  name='campainType'
                  value="delhi"
                  checked={selectedOption === "delhi"}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="delhi">  <img src={require('../assets/platform/delhi.png')} alt="Delhi" /> </label>
              </div>

              <div className='RadioButton'>
                <input
                  type='checkbox'
                  id="kolkata"
                  name='campainType'
                  value="kolkata"
                  checked={selectedOption === "kolkata"}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="kolkata">  <img src={require('../assets/platform/kolkata.png')} alt="kolkata" /> </label>
              </div>
            </div>
          </Form.Item>

          <Form.Item >
            <TextArea
              className='custom-textarea'
              rows={4} placeholder='Short Info. Company Name'
                onChange={(e)=>handleForm('Company_Info',e)}
               />
          </Form.Item>

          <Form.Item>
              <div className='d-flex custom-button'
                onClick={() => {
                  HandleSubmit()
                  // navigate('/Inventory')
                  // sessionStorage["PageNum"] = 2
                }}
              >
                <input type='submit' value='Next' />
                <ArrowRightOutlined className="site-form-item-icon" />
              </div>

          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Home

import React, { useState } from 'react'
import './CSS/Main.css'
import SideText from './SideText'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button,message } from 'antd';
import { useNavigate } from "react-router-dom"
import { UPLOAD_CLIENT_INFO } from '../common/apiFunction';


const Inventory = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (selectedOption === value) {
      setSelectedOption(null); // Unselect if the same checkbox is clicked again
    } else {
      setSelectedOption(value);
    }
  };

  const HandleSubmit = (()=>{
    if(selectedOption === null)
    {
      message.error(`Select Advertising Platform`);
    }
    else{
      let payload = {
        'Ads_Inventory':selectedOption
      }
      console.log(selectedOption)
      // UPLOAD_CLIENT_INFO(payload).then((data)=>{
        // console.log(data)
        navigate('/Format?type='+selectedOption.replaceAll(' ','_'))
        sessionStorage["PageNum"] = 3
      // })
    }
  })


  return (
    <div className='Home-container'>
      <img src={require('../assets/inventory.jpg')} className='Home-containerImg' alt='BG img' />
      <SideText pageno={[2]}/>
      <div className='Inventory-Right-container'>
        <h4 className='mb-3'> Ad Inventory </h4>


        <h6 style={{ 'color': '#fff' }} className='mb-3'>Please select where you want to showcase your brand?</h6>
        {/* <Radio.Group onChange={onChange} value={value} className='d-flex'> */}

        <div className='row d-flex mt-4 justify-content-around'>
          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="F&B_Menu" name='campainType' value="FNB Menu"
              checked={selectedOption === "FNB Menu"}
              onChange={handleCheckboxChange} />

            <label htmlFor="F&B_Menu"> <p>F&B Menu</p></label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Display_Ads" name='campainType' value="Display Ads"
              checked={selectedOption === "Display Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Display_Ads"> <p>Display Ads</p> </label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Home_Page_Ads" name='campainType' value="Home Page Ads"
              checked={selectedOption === "Home Page Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Home_Page_Ads"><p>Home Page Ads</p> </label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Other_Section_Display_Ads" name='campainType' value="Other Section Display Ads"
              checked={selectedOption === "Other Section Display Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Other_Section_Display_Ads"><p>Other Section Display Ads</p> </label>
          </div>
        </div>

        <div className='row mt-3 d-flex justify-content-around'>
          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Video_Ads" name='campainType' value="Video Ads"
              checked={selectedOption === "Video Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Video_Ads"><p>Video Ads</p> </label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Leas_Based_Ads" name='campainType' value="Leas Based Ads"
              checked={selectedOption === "Leas Based Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Leas_Based_Ads"><p>Leas Based Ads</p> </label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Brand_Showcase_Ads" name='campainType' value="Brand Showcase Ads"
              checked={selectedOption === "Brand Showcase Ads"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Brand_Showcase_Ads"><p>Brand Showcase Ads</p> </label>
          </div>

          <div className='col-sm-3 InventoryRadioButton'>
            <input type='checkbox' id="Brand_Sponsorship" name='campainType' value="Brand Sponsorship"
              checked={selectedOption === "Brand Sponsorship"}
              onChange={handleCheckboxChange} />
            <label htmlFor="Brand_Sponsorship"><p>Brand Sponsorship</p> </label>
          </div>

        </div>



        <div className='d-flex mt-4 mb-3'>
          <div className='d-flex custom-button-back'
            onClick={() => {
              navigate('/')
              sessionStorage["PageNum"] = 1
            }}
          >
            <ArrowLeftOutlined className="site-form-item-icon" />
            <input type='button' value='Back' />
          </div>

          <div className='d-flex custom-button'
            onClick={() => {
              HandleSubmit()
              // navigate('/Format')
              // sessionStorage["PageNum"] = 3
            }}
          >
            <input type='button' value='Next' />
            <ArrowRightOutlined className="site-form-item-icon" />
          </div>
        </div>


      </div>
    </div>
  )
}

export default Inventory

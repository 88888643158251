// FileUploadContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const FileUploadContext = createContext();

export function useFileUploadContext() {
  return useContext(FileUploadContext);
}

export function FileUploadProvider({ children }) {
  const [Thumbnail, SetThumbnail] = useState([]);

  useEffect(()=>{
    console.log(Thumbnail)
  },[Thumbnail])

  return (
    <FileUploadContext.Provider value={{ Thumbnail, SetThumbnail }}>
      {children}
    </FileUploadContext.Provider>
  );
}

import React, { useState } from 'react'
import './CSS/Main.css'
import SideText from './SideText'
import { useLocation, useNavigate } from "react-router-dom"
import { InboxOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message, Upload, Form, Input } from 'antd';
import { UPLOAD_CLIENT_ASSETS } from '../common/apiFunction';
import { useFileUploadContext } from '../context/file';
const { Dragger } = Upload;



const Assets = () => {
  const { Thumbnail } = useFileUploadContext();
  console.log(Thumbnail)

  const navigate = useNavigate();
  const [thumbnail1, setThumbnail1] = useState([])
  const [thumbnail2, setThumbnail2] = useState([])
  const [thumbnail3, setThumbnail3] = useState([])
  const [DriveLink, SetdriveLink] = useState('');

  const handleBeforeUpload = (file) => {
    console.log(file)
    // Add the selected file to the list
    setThumbnail1((prevSelectedFiles) => [...prevSelectedFiles, file]);
    return false;
  };

  const handleBeforeUpload2 = (file) => {
    console.log(file)
    // Add the selected file to the list
    setThumbnail2((prevSelectedFiles) => [...prevSelectedFiles, file]);
    return false;
  };

  const handleBeforeUpload3 = (file) => {
    console.log(file)
    // Add the selected file to the list
    setThumbnail3((prevSelectedFiles) => [...prevSelectedFiles, file]);
    return false;
  };

  const handleSave = (() => {
    console.log(Thumbnail, thumbnail1, thumbnail2, thumbnail3)
    if (thumbnail1.length === 0) {
      message.error(`Please Upload your campaign Thumbnails`);
    }
    else if (thumbnail2.length === 0) {
      message.error(`Please Upload your Landing Page assets`);
    }
    else {
      const payload = {
        file_url: Thumbnail[0],
        Email: sessionStorage["ClientEmail"],
        fnb_thumbnail: thumbnail1[0],
        landing_page_url: thumbnail2[0],
        drive_link: DriveLink,
        url: thumbnail3[0]
      }
      console.log(payload)
      UPLOAD_CLIENT_ASSETS(payload).then((data) => {
        console.log(data)
        if (data?.data?.status === true) {
          message.success('Details Saved Successfully')
          setTimeout(()=>{
            navigate('/')
          },3000)
        }
        else { message.error(data?.data?.message) }
      })
    }

  })

  // const handleFileChange = (event)=>{
  //   const file = event.target.files[0];
  //   // setSelectedFiles(file);
  //   setThumbnail1(file)
  // }


  return (
    <div className='Home-container'>
      <img src={require('../assets/thumbnail.png')} className='Home-containerImg' alt='BG img' />
      <SideText pageno={[2, 3, 4, 5]} />
      <div className='UploadAssets-Right-container' style={{ top: 80 }}>
        <h4 className='mb-2'>{sessionStorage["Ad_type"]} </h4>


        <p style={{ 'color': '#fff' }} className='mb-3'>File Upload</p>

        <p style={{ 'fontSize': '12px' }} >{sessionStorage["Ad_type"]} Thumbnail</p>
        {/* <input type='file' onChange={handleFileChange}/> */}
        <div>
          <Dragger beforeUpload={handleBeforeUpload} className='ThumbnailUpload' style={{ height: '20px' }}>
            <p className="ant-upload-drag-icon mb-1">
              <InboxOutlined className='FileUpload_Icon' />
            </p>
            <p className="ant-upload-hint fs-8 m-0">
              Choose Files
            </p>
          </Dragger>
        </div>


        <p style={{ 'fontSize': '12px' }} className='mt-3 mb-2'>Landing Page</p>
        <div>

          <Dragger beforeUpload={handleBeforeUpload2} className='ThumbnailUpload'>
            <p className="ant-upload-drag-icon mb-1">
              <InboxOutlined className='FileUpload_Icon' />
            </p>
            <p className="ant-upload-hint fs-8 m-0">
              Choose Files
            </p>
          </Dragger>
        </div>


        <div className='assetsLabel d-flex mt-3'>
          <label className='w-25'>Drive Link</label>
          <div className='w-75'>
            <Input type='text' onChange={(e) => SetdriveLink(e.target.value)} placeholder='Drive Link' />
            <p style={{ 'fontSize': '10px', 'color': '#c8c8c8' }} className='m-0 mt-1'> (Note: If the file size is more than 20 mb kindly paste the drive link below)</p>
          </div>
        </div>

        <p style={{ 'fontSize': '12px' }} className='mt-3 mb-2'>File Upload (Please upload asset as per given dimension (Max size 20mb))</p>
        <div>

          <Dragger beforeUpload={handleBeforeUpload3} className='ThumbnailUpload'>
            <p className="ant-upload-drag-icon mb-1">
              <InboxOutlined className='FileUpload_Icon' />
            </p>
            <p className="ant-upload-hint fs-8 m-0">
              Choose Files
            </p>
          </Dragger>
        </div>

        <div className='d-flex mt-4 mb-3'>
          <div className='d-flex custom-button-back'
            onClick={() => {
              navigate('/Upload-Thumbnail')
              sessionStorage["PageNum"] = 4
            }}
          >
            <ArrowLeftOutlined className="site-form-item-icon" />
            <input type='button' value='Back' />
          </div>

          <div className='d-flex justify-content-center custom-button'
            onClick={handleSave}>
            <input type='button' value='Save' />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Assets

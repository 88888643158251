import React, { useEffect, useState } from 'react'
import './CSS/Main.css'
import { Steps } from 'antd';
import {useNavigate} from "react-router-dom"


const SideText = ({...props}) => {
  const [selectedStep, setSelectedStep] = useState([1]);
  const navigate = useNavigate();

useEffect(() => {
  if (props?.pageno !== undefined) {
    // Parsing and flattening the incoming values
    const parsedValues = props?.pageno
      .map(str => JSON.parse(str))
      .flat(Infinity);

    setSelectedStep(prevSteps => {
      const newSteps = [...prevSteps, ...parsedValues.filter(val => !prevSteps.includes(val))];
      return newSteps;
    });
  }
}, [props?.pageno]);

  return (
    <>
    <div className='logo_img' onClick={()=>{navigate('/'); sessionStorage["PageNum"] = 1}}>
      <img src={require('../assets/logo.png')}/>
    </div>
      <div className='SideText'>
        <h2>A World of <span style={{ 'color': '#FEE25B' }}>Possibilities</span><br></br>at Your Fingertips </h2>

        <br></br>
        <h2 style={{ 'color': '#FEE25B' }}>100 M+</h2>
        <p>Customers</p>

        <br></br>
        <h2 style={{ 'color': '#FEE25B' }}>20 M+</h2>
        <p>Customers Database</p>

        <br></br>
        <h2 style={{ 'color': '#FEE25B' }}>12 M+</h2>
        <p>Active Customers Database</p>
      </div>

      <div className='sideBarSTeps'>
        {[1, 2, 3, 4, 5].map((step) => (
          <p
            key={step}
            className={`step ${selectedStep.includes(step) ? 'selected' : ''}  ${step == 1?'topRadius':''} ${step == (selectedStep.length)?'bottomRadius':''}`}
            // onClick={() => handleStepClick(step)}
          >
            {step}
          </p>
        ))}
      </div>


    </>

  )
}

export default SideText

import React, { useEffect } from 'react'
import './CSS/Main.css'
import SideText from './SideText'
import { useNavigate } from "react-router-dom"
// import { navigate } from '@reach/router'; 
import { InboxOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { useState } from 'react';
import { Navigate } from 'react-router';
import { useFileUploadContext } from '../context/file';
const { Dragger } = Upload;


const Thumbnail = () => {
  const { SetThumbnail } = useFileUploadContext();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const HandleSubmit = (() => {
    if (selectedFiles.length === 0) {
      message.error(`Please Upload your campaign assets`);
    }
    else {
      navigate('/Upload-Assets')
      sessionStorage["PageNum"] = 5
    }
  })


  const handleBeforeUpload = (file) => {
    console.log(file)
    // Add the selected file to the list
    SetThumbnail((prevSelectedFiles) => [...prevSelectedFiles, file]);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
    return false;
  };

  return (
    <div className='Home-container'>
      <img src={require('../assets/thumbnail.png')} className='Home-containerImg' alt='BG img' />
      <SideText pageno={[2,3,4]}/>
      <div className='Format-Right-container' style={{ top: 120 }}>
        <h4 className='mb-3'>Upload </h4>


        <h6 style={{ 'color': '#fff' }} className='mb-3'>File Upload</h6>
        <p style={{ 'fontSize': '12px' }}>Please Upload assets as per given dimension</p>
        {/* <Radio.Group onChange={onChange} value={value} className='d-flex'> */}

        <Dragger
          beforeUpload={handleBeforeUpload}
          className='ThumbnailUpload'
          style={{ height: '100px' }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint mt-3">
            Choose Files
          </p>
        </Dragger>

        <div className='d-flex mt-4 mb-3'>
          <div className='d-flex custom-button-back'
            onClick={() => {
              navigate('/Format')
              sessionStorage["PageNum"] = 3
            }}
          >
            <ArrowLeftOutlined className="site-form-item-icon" />
            <input type='button' value='Back' />
          </div>

          <div className='d-flex custom-button'
            onClick={() => {
              HandleSubmit()
              // navigate('/Upload-Assets')
              // sessionStorage["PageNum"] = 5
            }}
          >
            <input type='button' value='Next' />
            <ArrowRightOutlined className="site-form-item-icon" />
          </div>
        </div>


      </div>
    </div>
  )
}

export default Thumbnail

import { UPDATE_INFO, SAVE_INFO, UPLOAD_ASSETS } from "../constant/api_end"
import { PUT_API, POST_API } from "./apiMethod"
import { message } from 'antd';


export const SAVE_CLIENT_INFO = (async(RequestedData)=>{
    return POST_API(SAVE_INFO,RequestedData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        message.error(error?.message);
        return error
    })
})

export const UPLOAD_CLIENT_INFO = (async(RequestedData)=>{
    return POST_API(UPDATE_INFO,RequestedData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        console.log(error?.message)
        message.error(error?.message);
        return error
    })
})

export const UPLOAD_CLIENT_ASSETS = (async(RequestedData)=>{
    // console.log(RequestedData)
    const {file_url,Email,fnb_thumbnail,landing_page_url,drive_link,url} = RequestedData
    let RequestedFormData =  new FormData()
    RequestedFormData.append('file_url',file_url)
    RequestedFormData.append('Email',Email)
    RequestedFormData.append('fnb_thumbnail',fnb_thumbnail)
    RequestedFormData.append('landing_page_url',landing_page_url)
    RequestedFormData.append('drive_link',drive_link)
    RequestedFormData.append('url',url)

    return POST_API(UPLOAD_ASSETS,RequestedFormData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    })
})
